import { format } from "date-fns";

export const formatDate = (dateString) => {
  try {
    const date = new Date(dateString); // Convert to Date object
    return format(date, "MM/dd/yyyy"); // Format as "11/22/2024" using date-fns
  } catch (error) {
    console.error("Invalid date:", error);
    return "Invalid Date";
  }
};