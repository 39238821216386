import CloseIcon from "@mui/icons-material/Close";
import { Button, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef } from "react";
import Slide from "@mui/material/Slide";
import * as React from "react";
import { TransitionProps } from "@mui/material/transitions";
import { useNavigate } from "react-router-dom";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomModal = ({
  onClose,
  dialogTitleComponent,
  dialogTitle,
  disableBackdropClick,
  paperStyles,
  hideCloseBtn,
  rounded,
  children,
  open,
  setOpen,
  closeIconColor,
  closeIconStyles,
  actionButton,
}) => {
  const handleClose = () => {
    setOpen(false);
    onClose && onClose();
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  const navigate = useNavigate();
  return (
    <Dialog
      open={open}
      scroll={"body"}
      TransitionComponent={Transition}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        "& .MuiDialog-container": {
          margin: 0,
          "& .MuiPaper-root": {
            // this will remove top and bottom margin of dialog
            minHeight: { xs: "100vh", sm: "auto" },
            margin: { xs: 0, md: "32px" },
            width: { xs: "100%", sm: "90%", md: "80%", lg: "75%" },
            maxWidth: "550px",
            borderRadius: {
              xs: 0,
              sm: rounded ? "0.425rem" : 0,
              md: rounded ? "0.525rem" : 0,
              lg: rounded ? "0.625rem" : 0,
            },
            p: { xs: 2, md: 4 },
            bgcolor: "background.paper",
            ...paperStyles,
          },
        },
      }}
      fullWidth
      onClose={(event, reason) => {
        if (
          !(
            disableBackdropClick ||
            reason === "backdropClick" ||
            reason === "escapeKeyDown"
          )
        ) {
          // Set 'open' to false, however you would do that with your particular code.
          handleClose();
        }
      }}
    >
      {actionButton && (
        <Button
          sx={{
            color: "green",
            position: "absolute",
            top: 12,
            right: 50,
            zIndex: 10,
          }}
          onClick={() => {
            window.location.reload();
            // navigate("/", { state: { tag: null } });
            setOpen(false);
          }}
        >
          New Tag
        </Button>
      )}

      {/* <Link
        to={"/"}
        style={{
          color: "green",
          position: "absolute",
          top: 12,
          right: 50,
          zIndex: 10,
        }}
      >
        New Tag
      </Link> */}
      {!hideCloseBtn && (
        <IconButton
          disableRipple
          size="small"
          onClick={handleClose}
          sx={{
            "&:active": { transform: "scale(0.95)" },
            "&:hover": { backgroundColor: "#F6F6F6" },
            position: "absolute",
            top: 12,
            right: 20,
            zIndex: 10,
            ...closeIconStyles,
          }}
          aria-label="delete"
        >
          <CloseIcon
            sx={{
              fontSize: { xs: "2rem", sm: "1.5rem" },
              color: closeIconColor ?? "black",
            }}
          />
        </IconButton>
      )}

      {dialogTitleComponent && dialogTitleComponent}

      {dialogTitle && (
        <DialogTitle
          sx={{ fontWeight: 600, fontSize: " 1.25rem", color: "#1D1D1D" }}
          id="scroll-dialog-title"
        >
          {dialogTitle}
        </DialogTitle>
      )}
      <DialogContent
        dividers={false}
        sx={{
          p: 0,
        }}
      >
        {children}
      </DialogContent>
      {/* <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleClose}>Subscribe</Button>
                </DialogActions> */}
    </Dialog>
  );
};

export default CustomModal;

CustomModal.propTypes = {
  // ! REQUIRED
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired, // ?this function is required so that we can close the dialog from inside the dialog

  rounded: PropTypes.bool,
  hideCloseBtn: PropTypes.bool,
  paperStyles: PropTypes.object,
  disableBackdropClick: PropTypes.bool,
  dialogTitle: PropTypes.string,
  dialogTitleComponent: PropTypes.node, // if you want to pass custom component as dialog title
  onClose: PropTypes.func, // ? this function will be called when dialog is closed
  closeIconColor: PropTypes.object,
  closeIconStyles: PropTypes.object,
  actionButton: PropTypes.bool,
};

CustomModal.defaultProps = {
  rounded: true,
};
