import { createContext, useState } from "react";

export const TagContext = createContext({
  tags: [] as any[],
  setTags: (tags: any[]) => {},
});

export const TagProvider = ({ children }) => {
  const [tags, setTags] = useState<any[]>([]);

  return (
    <TagContext.Provider value={{ tags, setTags }}>
      {children}
    </TagContext.Provider>
  );
};
