import React, { useContext, useState } from "react";
import CustomModal from "./CustomModal";
import {
  Box,
  Button,
  CardContent,
  Divider,
  ListItemIcon,
  MenuItem,
  Typography,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useAuth from "../hooks/AuthHook";
import { BASE_URL } from "../config/constants";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useNotification from "../hooks/useNotification";

const MyMapsModal = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const { isLoggedIn } = useAuth();
  const { token } = useContext(AuthContext);
  const [maps, setMaps] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { notify, NotificationComponent } = useNotification();
  // setOpenModal(true);
  const handleMyTagsClick = async () => {
    setLoading(true);
    setOpenModal(true);
    if (isLoggedIn) {
      try {
        const response = await axios.get(`${BASE_URL}/api/dummy/my-maps`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setMaps(response.data.data); // Update myTags state with fresh data
          console.log("New myTags:", response.data.data);
        } else {
          notify("Error fetching data", "error");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // setMessage("No record found");
          notify("No record found", "info");
        } else {
          // setMessage("Error fetching data");
          notify("Error fetching data", "error");
        }
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setOpenModal(true); // Open modal
      }
    } else {
      navigate("/login"); // Redirect to login if not logged in
    }
  };
  return (
    <div>
      <MenuItem
        onClick={handleMyTagsClick}
        sx={{
          marginBottom: "10px",
          "&:hover": {
            color: "#f1356d",
          },
        }}
      >
        <ListItemIcon>
          <LocationOnIcon />
        </ListItemIcon>
        My Maps
      </MenuItem>
      <CustomModal
        open={openModal}
        setOpen={(event: React.SyntheticEvent) => {
          // if (event.target === event.currentTarget) return;
          setOpenModal(false);
          //   setModalContent(null);
        }}
        disableBackdropClick
        actionButton
        dialogTitleComponent={
          <Typography
            variant="h6"
            sx={{ marginBottom: 3, fontWeight: "bold", textAlign: "center" }}
          >
            My Maps
          </Typography>
        }
      >
        {maps?.length > 0 ? (
          maps?.map((tag: any, index: number) => (
            <div
              style={{ border: "1px solid #ccc", marginBottom: "10px" }}
              key={index + "map"}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      maxWidth: "200px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    title={tag?.groupLetter}
                  >
                    Map Group: {tag?.groupLetter?.toUpperCase()}
                  </Typography>
                  <Button
                    onClick={() => {
                      window.open(
                        `${tag.mapsLink}`,
                        "_blank",
                        "noopener,noreferrer"
                      );
                    }}
                    sx={{
                      color: "green",
                      borderColor: "green",
                      "&:hover": {
                        color: "white",
                        backgroundColor: "green",
                        borderColor: "green",
                      },
                    }}
                    variant="outlined"
                  >
                    Visit Map
                  </Button>
                </Box>
              </CardContent>
              <Divider />
            </div>
          ))
        ) : loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            Loading...
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            No Tags Found
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default MyMapsModal;
