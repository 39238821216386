import {
  Box,
  CardContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useContext } from "react";
import { alphabetArray } from "../utils/alphabetArray";
import MapPickerModal from "./MapPickerModal";
import { makeRequest } from "../config/api";
import { TagContext } from "../context/TagContext";
import { SelectChangeEvent } from "@mui/material/Select";

const MapTagComponent = ({ tag }: { tag: any }) => {
  const { tags, setTags } = useContext(TagContext);
  const [tagGroup, setTagGroup] = React.useState("");
  const [tagNumber, setTagNumber] = React.useState("");
  const [locationAddress, setLocationAddress] = React.useState("");

  // Sync all states with tag props
  useEffect(() => {
    setTagGroup(tag.groupLetter || "");
    setTagNumber(tag.groupNumber?.toString() || "");
    setLocationAddress(tag.location?.address || "");
  }, [tag.groupLetter, tag.groupNumber, tag.location?.address]);

  const handleUpdate = async (payload: any) => {
    try {
      const response = await makeRequest({
        method: "patch",
        endpoint: `/api/dummy/update/${tag._id}`,
        data: payload,
      });

      if (response.data?.data) {
        const updatedTags = tags.map((t) =>
          t._id === response.data.data._id ? response.data.data : t
        );
        setTags(updatedTags);
      }
    } catch (error) {
      console.error("Update failed:", error);
      // Revert local states on error
      setTagGroup(tag.groupLetter || "");
      setTagNumber(tag.groupNumber?.toString() || "");
      setLocationAddress(tag.location?.address || "");
    }
  };

  const handleGroupChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as string;
    setTagGroup(value); // Optimistic update
    handleUpdate({ groupLetter: value });
  };

  const handleNumberChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value as string;
    setTagNumber(value); // Optimistic update
    handleUpdate({ groupNumber: Number(value) });
  };

  // Modified location handler
  const handleLocationChange = async (
    location: string | { lat: number; lng: number; address: string }
  ) => {
    try {
      // Optimistically update local state
      if (typeof location === "string") {
        setLocationAddress(location);
      } else {
        setLocationAddress(location.address);
      }

      const payload =
        typeof location === "string"
          ? { location: { address: location } }
          : { location };

      await handleUpdate(payload);
    } catch (error) {
      console.error("Location update failed:", error);
    }
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 4fr",
        gap: "5px",
      }}
    >
      <FormControl variant="standard" sx={{ m: 1 }}>
        {/* <InputLabel>Group</InputLabel> */}
        <Select value={tagGroup} onChange={handleGroupChange} displayEmpty>
          <MenuItem disabled value="">
            <em>Abc</em>
          </MenuItem>
          {alphabetArray?.map((letter) => (
            <MenuItem key={letter.value} value={letter.value}>
              {letter.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="standard" sx={{ m: 1 }}>
        {/* <InputLabel>Number</InputLabel> */}
        <Select value={tagNumber} onChange={handleNumberChange} displayEmpty>
          <MenuItem disabled value="">
            <em>123</em>
          </MenuItem>
          {Array.from({ length: 30 }, (_, i) => (
            <MenuItem key={i + 1} value={i + 1}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <div style={{ border: "1px solid #ccc", marginBottom: "10px" }}>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <Typography
              variant="h6"
              sx={{
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={tag.title}
            >
              {tag.title}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <TextField
              size="small"
              variant="outlined"
              placeholder="Add Location"
              value={locationAddress}
              onChange={(e) => setLocationAddress(e.target.value)}
              fullWidth
              onBlur={(e) => handleLocationChange(e.target.value.trim())}
              sx={{ "& .MuiInputBase-input": { fontSize: "14px" } }}
            />
            <MapPickerModal
              onLocationSelected={(location) => handleLocationChange(location)}
            />
          </Box>
        </CardContent>
        <Divider />
      </div>
    </div>
  );
};

export default MapTagComponent;
