import React, { useState } from 'react';

const useNotification = () => {
  const [notifications, setNotifications] = useState([]);

  // Function to add a new notification
  const notify = (message, variant = 'info') => {
    const id = Date.now(); // Unique ID for each notification
    const newNotification = { id, message, variant };
    setNotifications((prev) => [...prev, newNotification]);

    // Auto-remove the notification after 5 seconds
    setTimeout(() => {
      removeNotification(id);
    }, 5000);
  };

  // Function to remove a notification by ID
  const removeNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  // Notification component to render
  const NotificationComponent = () => (
    <div style={{ width: '100%', maxWidth: '400px', margin: '0 auto' }}>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          style={{
            padding: '10px 20px',
            marginBottom: '10px',
            borderRadius: '4px',
            backgroundColor: getBackgroundColor(notification.variant),
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <span>{notification.message}</span>
          <button
            onClick={() => removeNotification(notification.id)}
            style={{
              background: 'none',
              border: 'none',
              color: '#fff',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
          >
            ✕
          </button>
        </div>
      ))}
    </div>
  );

  // Helper function to get background color based on variant
  const getBackgroundColor = (variant) => {
    switch (variant) {
      case 'success':
        return '#008000';
      case 'error':
        return '#ff0000';
      case 'warning':
        return '#ffc107';
      case 'info':
      default:
        return '#17a2b8';
    }
  };

  return { notify, NotificationComponent };
};

export default useNotification;