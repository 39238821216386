import React, { useContext, useState } from "react";
import CustomModal from "./CustomModal";
import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import useAuth from "../hooks/AuthHook";
import { BASE_URL } from "../config/constants";
import axios from "axios";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import useNotification from "../hooks/useNotification";
import MapTagComponent from "./MapTagComponent";
import { TagContext } from "../context/TagContext";

const labelStyle = {
  fontSize: "0.8rem",
  textAlign: "center" as const,
};
const WalkingMapModal = () => {
  const [openModal, setOpenModal] = React.useState(false);
  const { isLoggedIn } = useAuth();
  const { token } = useContext(AuthContext);
  const { tags, setTags } = useContext(TagContext);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { notify, NotificationComponent } = useNotification();
  // setOpenModal(true);
  const handleMyTagsClick = async () => {
    setLoading(true);
    setOpenModal(true);
    if (isLoggedIn) {
      try {
        const response = await axios.get(`${BASE_URL}/api/dummy/all`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          setTags(response.data.data); // Update myTags state with fresh data
          console.log("New myTags:", response.data.data);
        } else {
          notify("Error fetching data", "error");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // setMessage("No record found");
          notify("No record found", "info");
        } else {
          // setMessage("Error fetching data");
          notify("Error fetching data", "error");
        }
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
        setOpenModal(true); // Open modal
      }
    } else {
      navigate("/login"); // Redirect to login if not logged in
    }
  };
  return (
    <div>
      <MenuItem
        onClick={handleMyTagsClick}
        sx={{
          marginBottom: "10px",
          "&:hover": {
            color: "#f1356d",
          },
        }}
      >
        <ListItemIcon>
          <MergeTypeIcon />
        </ListItemIcon>
        Create Walking Map
      </MenuItem>
      <CustomModal
        open={openModal}
        setOpen={(event: React.SyntheticEvent) => {
          // if (event.target === event.currentTarget) return;
          setOpenModal(false);
          //   setModalContent(null);
        }}
        disableBackdropClick
        actionButton
        dialogTitleComponent={
          <Typography
            variant="h6"
            sx={{ marginBottom: 3, fontWeight: "bold", textAlign: "center" }}
          >
            Create Walking Map
          </Typography>
        }
      >
        
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 4fr",
            gap: "5px",
          }}
        >
          <p style={labelStyle}>Group Tags by Letter</p>
          <p style={labelStyle}>Order Tags by Number</p>
        </div>

        {tags?.length > 0 ? (
          tags?.map((tag: any, index: number) => <MapTagComponent tag={tag} key={tag._id} />)
        ) : loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            Loading...
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
            }}
          >
            No Tags Found
          </div>
        )}
      </CustomModal>
    </div>
  );
};

export default WalkingMapModal;
